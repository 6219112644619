var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Navbar'),_c('div',{staticClass:"signup-press duke-style"},[_c('div',{staticClass:"signup-container"},[_c('p',{staticClass:"p4"},[_vm._v("Join the club")]),_c('img',{staticClass:"mb-3",attrs:{"id":"club-logo","src":require("@/assets/logo/duke-club-logo-white.png"),"alt":"Duke Club"}}),_c('hr',{staticClass:"border"}),_c('h4',{staticClass:"mt-4"},[_vm._v("Create a press account")]),_c('div',{staticClass:"form"},[_c('div',{staticClass:"form-group"},[_vm._m(0),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userData.firstName),expression:"userData.firstName"}],staticClass:"input",class:{
            'form-control': true,
            'is-invalid': !_vm.validName(_vm.userData.firstName) && _vm.bluredFirstName
          },attrs:{"type":"text","placeholder":""},domProps:{"value":(_vm.userData.firstName)},on:{"blur":function($event){_vm.bluredFirstName = true},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.userData, "firstName", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please enter your first name. ")])]),_c('div',{staticClass:"form-group"},[_vm._m(1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userData.lastName),expression:"userData.lastName"}],staticClass:"input",class:{
            'form-control': true,
            'is-invalid': !_vm.validName(_vm.userData.lastName) && _vm.bluredLastName
          },attrs:{"type":"text","placeholder":""},domProps:{"value":(_vm.userData.lastName)},on:{"blur":function($event){_vm.bluredLastName = true},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.userData, "lastName", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please enter your first name. ")])]),_c('div',{staticClass:"form-group"},[_vm._m(2),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userData.email),expression:"userData.email"}],staticClass:"input",class:{
            'form-control': true,
            'is-invalid': !_vm.validEmail(_vm.userData.email) && _vm.bluredEmail
          },attrs:{"type":"email","placeholder":""},domProps:{"value":(_vm.userData.email)},on:{"blur":function($event){_vm.bluredEmail = true},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.userData, "email", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please enter a valid email. ")])]),_c('div',{staticClass:"form-group"},[_vm._m(3),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userData.phone),expression:"userData.phone"}],staticClass:"input",class:{
            'form-control': true,
            'is-invalid': !_vm.validPhone(_vm.userData.phone) && _vm.bluredPhone
          },attrs:{"type":"text","placeholder":""},domProps:{"value":(_vm.userData.phone)},on:{"blur":function($event){_vm.bluredPhone = true},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.userData, "phone", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please enter a valid phone number. ")])]),_c('div',{staticClass:"form-group"},[_vm._m(4),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userData.addr),expression:"userData.addr"}],staticClass:"input",class:{
            'form-control': true,
            'is-invalid': !_vm.validInput(_vm.userData.addr) && _vm.bluredAddress
          },attrs:{"type":"text","placeholder":""},domProps:{"value":(_vm.userData.addr)},on:{"blur":function($event){_vm.bluredAddress = true},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.userData, "addr", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please enter your address. ")])]),_c('div',{staticClass:"form-group"},[_vm._m(5),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userData.zip),expression:"userData.zip"}],staticClass:"input",class:{
            'form-control': true,
            'is-invalid': !_vm.validInput(_vm.userData.zip) && _vm.bluredZip
          },attrs:{"type":"text","placeholder":""},domProps:{"value":(_vm.userData.zip)},on:{"blur":function($event){_vm.bluredZip = true},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.userData, "zip", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please enter your zip code. ")])]),_c('div',{staticClass:"form-group"},[_vm._m(6),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userData.city),expression:"userData.city"}],staticClass:"input",class:{
            'form-control': true,
            'is-invalid': !_vm.validInput(_vm.userData.city) && _vm.bluredCity
          },attrs:{"type":"text","placeholder":""},domProps:{"value":(_vm.userData.city)},on:{"blur":function($event){_vm.bluredCity = true},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.userData, "city", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please enter your city. ")])]),_c('div',{staticClass:"form-group"},[_vm._m(7),_c('b-form-select',{staticClass:"input-select",attrs:{"options":_vm.countriesList},model:{value:(_vm.userData.country),callback:function ($$v) {_vm.$set(_vm.userData, "country", $$v)},expression:"userData.country"}})],1),_c('div',{staticClass:"form-group"},[_vm._m(8),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.companyData.pressId),expression:"companyData.pressId"}],staticClass:"input",class:{
            'form-control': true,
            'is-invalid': !_vm.validInput(_vm.companyData.pressId) && _vm.bluredPressId
          },attrs:{"type":"text","placeholder":""},domProps:{"value":(_vm.companyData.pressId)},on:{"blur":function($event){_vm.bluredPressId = true},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.companyData, "pressId", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please enter your press ID. ")])]),_c('div',{staticClass:"form-group"},[_vm._m(9),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userData.password),expression:"userData.password"}],staticClass:"input",class:{
            'form-control': true,
            'is-invalid': !_vm.validPassword(_vm.userData.password) && _vm.bluredPassword
          },attrs:{"type":"password","placeholder":""},domProps:{"value":(_vm.userData.password)},on:{"blur":function($event){_vm.bluredPassword = true},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.userData, "password", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Your password must be at least 8 charachters long. ")])]),_c('div',{staticClass:"form-group"},[_vm._m(10),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userData.passwordCheck),expression:"userData.passwordCheck"}],staticClass:"input",class:{
            'form-control': true,
            'is-invalid':
              !_vm.validPasswordMatch(_vm.userData.password, _vm.userData.passwordCheck) && _vm.bluredPasswordCheck
          },attrs:{"type":"password","placeholder":""},domProps:{"value":(_vm.userData.passwordCheck)},on:{"blur":function($event){_vm.bluredPasswordCheck = true},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.userData, "passwordCheck", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Passwords don't match. ")])]),_vm._m(11),_c('div',{staticClass:"form-group"},[_vm._m(12),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.companyData.name),expression:"companyData.name"}],staticClass:"input",class:{
            'form-control': true,
            'is-invalid': !_vm.validName(_vm.companyData.name) && _vm.bluredCompanyName
          },attrs:{"type":"text","placeholder":""},domProps:{"value":(_vm.companyData.name)},on:{"blur":function($event){_vm.bluredCompanyName = true},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.companyData, "name", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please enter the name of your company. ")])]),_c('div',{staticClass:"form-group"},[_vm._m(13),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.companyData.phone),expression:"companyData.phone"}],staticClass:"input",class:{
            'form-control': true,
            'is-invalid': !_vm.validPhone(_vm.companyData.phone) && _vm.bluredCompanyPhone
          },attrs:{"type":"text","placeholder":""},domProps:{"value":(_vm.companyData.phone)},on:{"blur":function($event){_vm.bluredCompanyPhone = true},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.companyData, "phone", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please enter the phone number of your company. ")])]),_c('div',{staticClass:"form-group"},[_vm._m(14),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.companyData.addr),expression:"companyData.addr"}],staticClass:"input",class:{
            'form-control': true,
            'is-invalid': !_vm.validInput(_vm.companyData.addr) && _vm.bluredCompanyAddress
          },attrs:{"type":"text","placeholder":""},domProps:{"value":(_vm.companyData.addr)},on:{"blur":function($event){_vm.bluredCompanyAddress = true},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.companyData, "addr", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please enter the address of your company. ")])]),_c('div',{staticClass:"form-group"},[_vm._m(15),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.companyData.zip),expression:"companyData.zip"}],staticClass:"input",class:{
            'form-control': true,
            'is-invalid': !_vm.validInput(_vm.companyData.zip) && _vm.bluredCompanyZip
          },attrs:{"type":"text","placeholder":""},domProps:{"value":(_vm.companyData.zip)},on:{"blur":function($event){_vm.bluredCompanyZip = true},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.companyData, "zip", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please enter the zip code of your company. ")])]),_c('div',{staticClass:"form-group"},[_vm._m(16),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.companyData.city),expression:"companyData.city"}],staticClass:"input",class:{
            'form-control': true,
            'is-invalid': !_vm.validInput(_vm.companyData.city) && _vm.bluredCompanyCity
          },attrs:{"type":"text","placeholder":""},domProps:{"value":(_vm.companyData.city)},on:{"blur":function($event){_vm.bluredCompanyCity = true},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.companyData, "city", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please enter the city of your company. ")])]),_c('div',{staticClass:"form-group"},[_vm._m(17),_c('b-form-select',{staticClass:"input-select",attrs:{"options":_vm.countriesList},model:{value:(_vm.companyData.country),callback:function ($$v) {_vm.$set(_vm.companyData, "country", $$v)},expression:"companyData.country"}})],1)]),_c('hr',{staticClass:"border"}),_c('button',{staticClass:"jbtn jbtn-metro-round jbtn-white",attrs:{"type":"button"},on:{"click":_vm.registerUser}},[(_vm.isSaving)?_c('span',{key:"spinner",staticClass:"m-0"},[_c('i',{staticClass:"fad fa-spinner-third fa-spin"})]):_c('span',{key:"button"},[_vm._v("Signup")])]),(_vm.validationMsg)?_c('b-alert',{staticClass:"mt-5",attrs:{"show":"","variant":"secondary"}},[_c('i',{staticClass:"fal fa-exclamation-circle"}),_vm._v(" "+_vm._s(_vm.validationMsg)+" "+_vm._s(_vm.pwNotOkMsg)+" ")]):_vm._e(),_c('p',{staticClass:"p-text-xs"},[_vm._v(" Please make sure to provide accurate information in order to ensure that Duke can offer the best possible service. ")])],1),_c('div',{staticClass:"cover"})])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"p4"},[_c('i',{staticClass:"fal fa-user"}),_vm._v(" First Name")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"p4"},[_c('i',{staticClass:"fal fa-user"}),_vm._v(" Last Name")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"p4"},[_c('i',{staticClass:"fal fa-envelope"}),_vm._v(" Email")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"p4"},[_c('i',{staticClass:"fal fa-phone-alt"}),_vm._v(" Phone")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"p4"},[_c('i',{staticClass:"fal fa-house"}),_vm._v(" Address")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"p4"},[_c('i',{staticClass:"fal fa-map-marker-alt"}),_vm._v(" Zip")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"p4"},[_c('i',{staticClass:"fal fa-city"}),_vm._v(" City")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"p4"},[_c('i',{staticClass:"fal fa-globe-europe"}),_vm._v(" Country")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"p4"},[_c('i',{staticClass:"fal fa-id-card-alt"}),_vm._v(" Press ID")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"p4",attrs:{"for":"password"}},[_c('i',{staticClass:"fal fa-lock"}),_vm._v(" Password")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"p4",attrs:{"for":"password"}},[_c('i',{staticClass:"fal fa-lock"}),_vm._v(" Confirm Password")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{staticClass:"mt-4 mb-4 grid-2"},[_c('i',{staticClass:"fal fa-building"}),_vm._v(" Company")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"p4"},[_c('i',{staticClass:"fal fa-building"}),_vm._v(" Name")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"p4"},[_c('i',{staticClass:"fal fa-phone-alt"}),_vm._v(" Phone")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"p4"},[_c('i',{staticClass:"fal fa-building"}),_vm._v(" Address")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"p4"},[_c('i',{staticClass:"fal fa-map-marker-alt"}),_vm._v(" Zip")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"p4"},[_c('i',{staticClass:"fal fa-city"}),_vm._v(" City")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"p4"},[_c('i',{staticClass:"fal fa-globe-europe"}),_vm._v(" Country")])
}]

export { render, staticRenderFns }