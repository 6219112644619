<template>
  <div>
    <Navbar></Navbar>

    <!-- SIGNUP PRESS -->
    <div class="signup-press duke-style">
      <div class="signup-container">
        <!-- title -->
        <p class="p4">Join the club</p>
        <!-- <h1>Duke CLub</h1> -->
        <img id="club-logo" class="mb-3" src="@/assets/logo/duke-club-logo-white.png" alt="Duke Club" />
        <hr class="border" />
        <h4 class="mt-4">Create a press account</h4>

        <!-- form -->
        <div class="form">
          <!-- first name -->
          <div class="form-group">
            <p class="p4"><i class="fal fa-user"></i> First Name</p>
            <input type="text" class="input" placeholder="" v-model="userData.firstName" v-bind:class="{
              'form-control': true,
              'is-invalid': !validName(userData.firstName) && bluredFirstName
            }" v-on:blur="bluredFirstName = true" />
            <div class="invalid-feedback">
              Please enter your first name.
            </div>
          </div>

          <!-- last name -->
          <div class="form-group">
            <p class="p4"><i class="fal fa-user"></i> Last Name</p>
            <input type="text" class="input" placeholder="" v-model="userData.lastName" v-bind:class="{
              'form-control': true,
              'is-invalid': !validName(userData.lastName) && bluredLastName
            }" v-on:blur="bluredLastName = true" />
            <div class="invalid-feedback">
              Please enter your first name.
            </div>
          </div>

          <!-- email -->
          <div class="form-group">
            <p class="p4"><i class="fal fa-envelope"></i> Email</p>
            <input type="email" class="input" placeholder="" v-model="userData.email" v-bind:class="{
              'form-control': true,
              'is-invalid': !validEmail(userData.email) && bluredEmail
            }" v-on:blur="bluredEmail = true" />
            <div class="invalid-feedback">
              Please enter a valid email.
            </div>
          </div>

          <!-- phone -->
          <div class="form-group">
            <p class="p4"><i class="fal fa-phone-alt"></i> Phone</p>
            <input type="text" class="input" placeholder="" v-model="userData.phone" v-bind:class="{
              'form-control': true,
              'is-invalid': !validPhone(userData.phone) && bluredPhone
            }" v-on:blur="bluredPhone = true" />
            <div class="invalid-feedback">
              Please enter a valid phone number.
            </div>
          </div>

          <!-- address -->
          <div class="form-group">
            <p class="p4"><i class="fal fa-house"></i> Address</p>
            <input type="text" class="input" placeholder="" v-model="userData.addr" v-bind:class="{
              'form-control': true,
              'is-invalid': !validInput(userData.addr) && bluredAddress
            }" v-on:blur="bluredAddress = true" />
            <div class="invalid-feedback">
              Please enter your address.
            </div>
          </div>

          <!-- zip -->
          <div class="form-group">
            <p class="p4"><i class="fal fa-map-marker-alt"></i> Zip</p>
            <input type="text" class="input" placeholder="" v-model="userData.zip" v-bind:class="{
              'form-control': true,
              'is-invalid': !validInput(userData.zip) && bluredZip
            }" v-on:blur="bluredZip = true" />
            <div class="invalid-feedback">
              Please enter your zip code.
            </div>
          </div>

          <!-- city -->
          <div class="form-group">
            <p class="p4"><i class="fal fa-city"></i> City</p>
            <input type="text" class="input" placeholder="" v-model="userData.city" v-bind:class="{
              'form-control': true,
              'is-invalid': !validInput(userData.city) && bluredCity
            }" v-on:blur="bluredCity = true" />
            <div class="invalid-feedback">
              Please enter your city.
            </div>
          </div>

          <!-- country -->
          <div class="form-group">
            <p class="p4"><i class="fal fa-globe-europe"></i> Country</p>
            <b-form-select class="input-select" :options="countriesList" v-model="userData.country"></b-form-select>
          </div>

          <!-- press id -->
          <div class="form-group">
            <p class="p4"><i class="fal fa-id-card-alt"></i> Press ID</p>
            <input type="text" class="input" placeholder="" v-model="companyData.pressId" v-bind:class="{
              'form-control': true,
              'is-invalid': !validInput(companyData.pressId) && bluredPressId
            }" v-on:blur="bluredPressId = true" />
            <div class="invalid-feedback">
              Please enter your press ID.
            </div>
          </div>

          <!-- password -->
          <div class="form-group">
            <p for="password" class="p4"><i class="fal fa-lock"></i> Password</p>
            <input type="password" class="input" placeholder="" v-model="userData.password" v-bind:class="{
              'form-control': true,
              'is-invalid': !validPassword(userData.password) && bluredPassword
            }" v-on:blur="bluredPassword = true" />
            <div class="invalid-feedback">
              Your password must be at least 8 charachters long.
            </div>
          </div>

          <!-- confirm password -->
          <div class="form-group">
            <p for="password" class="p4"><i class="fal fa-lock"></i> Confirm Password</p>
            <input type="password" class="input" placeholder="" v-model="userData.passwordCheck" v-bind:class="{
              'form-control': true,
              'is-invalid':
                !validPasswordMatch(userData.password, userData.passwordCheck) && bluredPasswordCheck
            }" v-on:blur="bluredPasswordCheck = true" />
            <div class="invalid-feedback">
              Passwords don't match.
            </div>
          </div>

          <!-- COMPANY -->
          <h4 class="mt-4 mb-4 grid-2"><i class="fal fa-building"></i> Company</h4>

          <!-- company name -->
          <div class="form-group">
            <p class="p4"><i class="fal fa-building"></i> Name</p>
            <input type="text" class="input" placeholder="" v-model="companyData.name" v-bind:class="{
              'form-control': true,
              'is-invalid': !validName(companyData.name) && bluredCompanyName
            }" v-on:blur="bluredCompanyName = true" />
            <div class="invalid-feedback">
              Please enter the name of your company.
            </div>
          </div>

          <!-- company email -->
          <!-- <div class="form-group">
            <p class="p4"><i class="fal fa-envelope"></i> Email</p>
            <input type="text" class="input" placeholder="" v-model="companyData.email" v-bind:class="{
                'form-control': true,
                'is-invalid': !validEmail(companyData.email) && bluredCompanyEmail
              }"
              v-on:blur="bluredCompanyEmail = true"
            />
            <div class="invalid-feedback">
              Please enter your name.
            </div>
          </div> -->

          <!-- company phone -->
          <div class="form-group">
            <p class="p4"><i class="fal fa-phone-alt"></i> Phone</p>
            <input type="text" class="input" placeholder="" v-model="companyData.phone" v-bind:class="{
              'form-control': true,
              'is-invalid': !validPhone(companyData.phone) && bluredCompanyPhone
            }" v-on:blur="bluredCompanyPhone = true" />
            <div class="invalid-feedback">
              Please enter the phone number of your company.
            </div>
          </div>

          <!-- company address -->
          <div class="form-group">
            <p class="p4"><i class="fal fa-building"></i> Address</p>
            <input type="text" class="input" placeholder="" v-model="companyData.addr" v-bind:class="{
              'form-control': true,
              'is-invalid': !validInput(companyData.addr) && bluredCompanyAddress
            }" v-on:blur="bluredCompanyAddress = true" />
            <div class="invalid-feedback">
              Please enter the address of your company.
            </div>
          </div>

          <!-- company zip -->
          <div class="form-group">
            <p class="p4"><i class="fal fa-map-marker-alt"></i> Zip</p>
            <input type="text" class="input" placeholder="" v-model="companyData.zip" v-bind:class="{
              'form-control': true,
              'is-invalid': !validInput(companyData.zip) && bluredCompanyZip
            }" v-on:blur="bluredCompanyZip = true" />
            <div class="invalid-feedback">
              Please enter the zip code of your company.
            </div>
          </div>

          <!-- company city -->
          <div class="form-group">
            <p class="p4"><i class="fal fa-city"></i> City</p>
            <input type="text" class="input" placeholder="" v-model="companyData.city" v-bind:class="{
              'form-control': true,
              'is-invalid': !validInput(companyData.city) && bluredCompanyCity
            }" v-on:blur="bluredCompanyCity = true" />
            <div class="invalid-feedback">
              Please enter the city of your company.
            </div>
          </div>

          <!-- company country -->
          <div class="form-group">
            <p class="p4"><i class="fal fa-globe-europe"></i> Country</p>
            <b-form-select class="input-select" :options="countriesList" v-model="companyData.country"></b-form-select>
          </div>
        </div>

        <!-- border -->
        <hr class="border" />
        <!-- button -->
        <button type="button" class="jbtn jbtn-metro-round jbtn-white" @click="registerUser">
          <span v-if="isSaving" key="spinner" class="m-0"><i class="fad fa-spinner-third fa-spin"></i></span>
          <span v-else key="button">Signup</span>
        </button>

        <!-- alert -->
        <b-alert v-if="validationMsg" class=" mt-5" show variant="secondary"><i class="fal fa-exclamation-circle"></i>
          {{ validationMsg }}
          {{ pwNotOkMsg }}
        </b-alert>
        <!-- <p>{{ pwNotOkMsg }}</p> -->

        <p class="p-text-xs">
          Please make sure to provide accurate information in order to ensure that Duke can offer the best
          possible service.
        </p>
      </div>
      <!-- photo -->
      <div class="cover"></div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/navigation/NavbarPublic";
// import Footer from "@/components/navigation/Footer";
import C from "@/const";
import CL from "@/countries";
import firebase from "@/firebase/firebaseInit";
const db = firebase.db;
const auth = firebase.auth;
const serverTimestamp = firebase.serverTimestamp;

export default {
  name: "Register",
  components: {
    Navbar: Navbar
    // Footer: Footer,
  },
  data() {
    return {
      title: "Create account",

      //page status
      isSaving: false,
      //form error handling
      valid: false,
      validCompany: false,
      showInputError: false,
      validationMsg: "",
      pwNotOkMsg: "",
      // blured inputs
      bluredFirstName: false,
      bluredLastName: false,
      bluredEmail: false,
      bluredPhone: false,
      bluredAddress: false,
      bluredZip: false,
      bluredCity: false,
      bluredCountry: false,
      bluredPassword: false,
      bluredPasswordCheck: false,
      // blured press
      bluredPressId: false,
      bluredCompanyName: false,
      // bluredCompanyEmail: false,
      bluredCompanyPhone: false,
      bluredCompanyAddress: false,
      bluredCompanyZip: false,
      bluredCompanyCity: false,
      bluredCompanyCountry: false,

      companyData: {
        pressId: "",
        name: "",
        email: "",
        phone: "",
        addr: "",
        zip: "",
        city: "",
        country: "Luxembourg"
      },
      userData: {
        firstName: "",
        lastName: "",
        email: "",
        addr: "",
        zip: "",
        city: "",
        country: "Luxembourg",
        phone: "",
        password: "",
        passwordCheck: "",
        type: 0,
        status: 0, // expired
        company: this.companyData
      },
      countriesList: CL.COUNTRIES
    };
  },
  methods: {
    registerUser() {
      this.isSaving = true;
      if (!this.validate() || !this.validateCompany()) {
        this.validationMsg = "Please complete the form";
        this.isSaving = false;
        return;
      }

      // check if user is logged in already which might lead to conflicts with firebase auth, vue store etc
      if (auth.currentUser) {
        auth.signOut().then
        auth.signOut().then(() => {
          this.registerUser();
        });
        return;
      }

      this.validationMsg = "";
      //create auth user
      auth
        .createUserWithEmailAndPassword(this.userData.email, this.userData.password)
        .then(() => {
          //update auth user
          // TODO: DOUBLE CHCEK SECURITY (input sanitation?)
          auth.currentUser
            .updateProfile({
              displayName: this.userData.firstName + " " + this.userData.lastName
            })
            .then(() => {
              //create user doc
              this.validationMsg = "Welcome " + auth.currentUser.displayName;
              const userRef = db.collection(C.COLLECTION.USERS).doc(auth.currentUser.uid);
              userRef
                .set({
                  createdOn: serverTimestamp,
                  firstName: this.userData.firstName,
                  lastName: this.userData.lastName,
                  email: this.userData.email,
                  phone: this.userData.phone,
                  addr: this.userData.addr,
                  zip: this.userData.zip,
                  city: this.userData.city,
                  country: this.userData.country,
                  pressId: this.companyData.pressId,
                  company: this.companyData,
                  type: C.TYPE.PRESS,
                  status: C.STATUS.STATUS.UNVERIFIED
                })
                .then(() => {
                  const emailTrigger = {
                    type: C.EMAIL_TYPE.VERIFY,
                    press: true,
                    actionLink: C.CLUB_URL + "press",
                    email: this.userData.email,
                  };
                  db.collection(C.COLLECTION.EMAILS).doc().set(emailTrigger).then(() => {
                    // TODO: maybe do it async right away instead?
                    console.log("VERIFICATION MAIL SENT");
                  })
                    .catch(err => {
                      console.log("COULD NOT SEND VERIFICATION MAIL");
                      this.validationMsg = err.message;
                    });

                  userRef.onSnapshot(snap => {
                    const user = snap.data();
                    if (!user || !user.refresh) {
                      console.log("User not created or ready.");
                      return;
                    }

                    console.log("Refreshing user");
                    // let store redirect to the right dashboard after getting the claims
                    this.$store.commit("setNewRoute", "Dashboard");
                    this.$store.dispatch("updateUser", auth.currentUser);
                    userRef.update({
                      refresh: false
                    });
                    this.isSaving = false;
                  })
                })
                .catch(err => {
                  this.pwNotOkMsg = "COULD NOT CREATE DOCUMENT.";
                  this.validationMsg = err.message;
                  this.isSaving = false;
                });
            })
            .catch(err => {
              this.pwNotOkMsg = "COULD NOT UPDATE PROFILE.";
              this.validationMsg = err.message;
              this.isSaving = false;
            });
        })
        .catch(err => {
          this.pwNotOkMsg = "COULD NOT CREATE USER.";
          this.validationMsg = err.message;
          this.isSaving = false;
        });
    },
    validate() {
      // Check name
      this.bluredFirstName = true;
      if (!this.validName(this.userData.firstName)) {
        this.valid = false;
        return false;
      } // Check name
      this.bluredLastName = true;
      if (!this.validName(this.userData.lastName)) {
        this.valid = false;
        return false;
      }
      // Check email
      this.bluredEmail = true;
      this.userData.email = this.userData.email.trim().toLowerCase();
      if (!this.validEmail(this.userData.email)) {
        this.valid = false;
        return false;
      }
      // Check phone
      this.bluredPhone = true;
      if (!this.validPhone(this.userData.phone)) {
        this.valid = false;
        return false;
      }
      // Check address
      this.bluredAddress = true;
      if (!this.validInput(this.userData.addr)) {
        this.valid = false;
        return false;
      }
      // Check zip
      this.bluredZip = true;
      if (!this.validInput(this.userData.zip)) {
        this.valid = false;
        return false;
      }
      // Check city
      this.bluredCity = true;
      if (!this.validInput(this.userData.city)) {
        this.valid = false;
        return false;
      }

      //Check password
      this.bluredPassword = true;
      if (!this.validPassword(this.userData.password)) {
        this.valid = false;
        this.pwNotOkMsg = "Your password is too short (8 characters min.)";
        return false;
      }
      //Check confirm password
      this.bluredPasswordCheck = true;
      if (!this.validPasswordMatch(this.userData.password, this.userData.passwordCheck)) {
        this.valid = false;
        this.pwNotOkMsg = "Your passwords did not match.";
        return false;
      }
      this.pwNotOkMsg = "";
      this.valid = true;
      return true;
    },
    validateCompany() {
      // Check press id
      this.bluredPressId = true;
      if (!this.validInput(this.companyData.pressId)) {
        this.valid = false;
        return false;
      }
      // Check company name
      this.bluredCompanyName = true;
      if (!this.validName(this.companyData.name)) {
        this.valid = false;
        return false;
      }
      // Check company email
      // this.bluredCompanyEmail = true;
      // this.companyData.email = this.companyData.email.trim().toLowerCase();
      // if (!this.validEmail(this.companyData.email)) {
      //   this.valid = false;
      //   return false;
      // }
      // Check company phone
      this.bluredCompanyPhone = true;
      if (!this.validPhone(this.companyData.phone)) {
        this.valid = false;
        return false;
      }
      // Check company address
      this.bluredCompanyAddress = true;
      if (!this.validInput(this.companyData.addr)) {
        this.valid = false;
        return false;
      }
      // Check company zip
      this.bluredCompanyZip = true;
      if (!this.validInput(this.companyData.zip)) {
        this.valid = false;
        return false;
      }
      // Check company city
      this.bluredCompanyCity = true;
      if (!this.validInput(this.companyData.city)) {
        this.valid = false;
        return false;
      }

      this.validCompany = true;
      return true;
    },
    validInput(input) {
      return input.trim().length > 0;
    },
    validEmail(email) {
      var re = /(.+)@(.+){2,}\.(.+){2,}/;
      return re.test(email.toLowerCase());
    },
    validName(firstName) {
      return firstName.trim().length > 2;
    },
    validPhone(phone) {
      return phone.trim().length > 5;
    },
    validPassword(password) {
      return password.trim().length > 7;
    },
    validPasswordMatch(password, passwordCheck) {
      return !(password != passwordCheck);
    },
    checkEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    checkPhone: function (phone) {
      var re = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/g;
      return re.test(String(phone).toLowerCase());
    },
  }
};
</script>

<style lang="scss" scoped></style>
